import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import {Container,Row,Col} from 'react-bootstrap'



const hardware =()=>{
    return(
        <Layout>
            <SEO title="Software" />
            <Container >
                <Row style={{marginTop:"60px"}}>
                    <Col sm={12} lg={6}><Image alt="software" filename="software-1.png" /></Col>
                    <Col sm={12} lg={6} className="softPara">
                        <h3 className="softwareh3">SOFTWARE DEVELOPMENT SERVICES</h3> 
                        <p>We build customized software solutions according to the latest technical standards for all industries and sectors, in almost all available programming languages and for cross platforms. Your business will benefit from the extensive know-how and experience of our developers, providing superior quality software solutions. Within a short span of time, Trace Width Technology Solutions has emerged as a front runner in Customized Software Development in the Tampa region thanks to our highly skilled and experienced team of developers and competent technical support engineers. By implementing stringent quality assurance and Project Management procedures we are able to produce robust software solutions in a most cost effect manner.</p>
                        <p>At Trace Width Technology Solutions, We assess and understand your business needs, define the software requirements, and write detailed technical specifications by involving our technical managers, designers, and solution architects. We then develop, test, and build the custom software for you.</p>
                    </Col>
                </Row>
            </Container>

            <div className="headerForm"> 
                <br/>
                <div className="homeCardrow5">Trace Width has the software expertise you’ve been looking for, let’s get started today!</div>
                    <ContactForm  />
            </div>

        </Layout>
    )
};

export default hardware;